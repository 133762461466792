import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'
import Contact from './Contact'

const Main = props => {
 
  const close = (
    <button
      className="close"
      onClick={() => {
        props.onCloseArticle()
      }}
    >
      &nbsp;
    </button>
  )
  
  return (
    <div
      ref={props.setWrapperRef}
      id="main"
      style={
        props.timeout
          ? {
              display: 'flex',
            }
          : {
              display: 'none',
            }
      }
    >
      <article
        id="intro"
        className={`${props.article === 'intro' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{
          display: 'none',
        }}
      >
        <h2 className="major">
          <FormattedMessage id="pages.intro.title" />
        </h2>
        <p>
          <FormattedMessage id="pages.intro.content" />
        </p>
        {close}
      </article>

      <article
        id="work"
        className={`${props.article === 'work' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{
          display: 'none',
        }}
      >
        <h2 className="major">
          <FormattedMessage id="pages.work.title" />
        </h2>
        <br />

        <h3><FormattedMessage id="pages.work.translation.title" /></h3>
        <p>
          <FormattedMessage id="pages.work.translation.description" />
        </p>

        <h3><FormattedMessage id="pages.work.localization.title" /></h3>
        <p>
          <FormattedMessage id="pages.work.localization.description" />
        </p>

        <h3><FormattedMessage id="pages.work.transcription.title" /></h3>
        <p>
          <FormattedMessage id="pages.work.transcription.description" />
        </p>

        <h3><FormattedMessage id="pages.work.proofreading.title" /></h3>
        <p>
          <FormattedMessage id="pages.work.proofreading.description" />
        </p>

        <h3><FormattedMessage id="pages.work.consulting.title" /></h3>
        <p>
          <FormattedMessage id="pages.work.consulting.description" />
        </p>


        {close}
      </article>

      <article
        id="about"
        className={`${props.article === 'about' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{
          display: 'none',
        }}
      >
        <h2 className="major">
          <FormattedMessage id="pages.about.title" />
        </h2>

        <p>
          <FormattedMessage id="pages.about.content" />
        </p>

        <p>
          <FormattedMessage id="pages.about.skills" />
        </p>

        <p><FormattedMessage id="pages.about.labelLinkedin" />
          <a
            href="https://www.linkedin.com/in/brunamartinelli/"
            className="icon fa-brands fa-linkedin"
            target="blank"
          >
            <span className="label">Linkedin</span>
          </a>
        </p>
        {close}
      </article>

      <article
        id="contact"
        className={`${props.article === 'contact' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">
          <FormattedMessage id="pages.contact.title" />
        </h2>
        <>
          <Contact />
        </>
        {close}    
      </article>
    </div>
  )
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default injectIntl(Main)
