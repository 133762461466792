import React from 'react'
import { useForm } from '@formspree/react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

const Contact = () => {
  const [submitState, handleSubmit] = useForm("mnqravvv");
  const intl = useIntl()

  return (
    <div id="contact">
      {submitState.succeeded ?
        <h3><FormattedMessage id="pages.contact.feedbackMessage" /></h3>
        : (
          <form method="post" onSubmit={handleSubmit}>
            <div className="field first">
              <label
                htmlFor={intl.formatMessage({ id: 'pages.contact.labelName' })}
              >
                <FormattedMessage id="pages.contact.labelName" />
              </label>
              <input
                type="text"
                name={intl.formatMessage({ id: 'pages.contact.labelName' })}
                id="name"
                required
              />
            </div>
            <div className="field half first">
              <label
                htmlFor={intl.formatMessage({ id: 'pages.contact.labelEmail' })}
              >
                <FormattedMessage id="pages.contact.labelEmail" />
              </label>
              <input
                type="email"
                name={intl.formatMessage({ id: 'pages.contact.labelEmail' })}
                id="email"
                required
              />
            </div>
            <div className="field half">
              <label
                htmlFor={intl.formatMessage({ id: 'pages.contact.labelPhone' })}
              >
                <FormattedMessage id="pages.contact.labelPhone" />
              </label>
              <input
                type="tel"
                name={intl.formatMessage({ id: 'pages.contact.labelPhone' })}
                id="phone"
              />
            </div>
            <div className="field half first">
              <label
                htmlFor={intl.formatMessage({ id: 'pages.contact.labelSourceLanguage' })}
              >
                <FormattedMessage id="pages.contact.labelSourceLanguage" />
              </label>
              <input
                type="text"
                name={intl.formatMessage({ id: 'pages.contact.labelSourceLanguage' })}
                id="source"
              />
            </div>
            <div className="field half">
              <label
                htmlFor={intl.formatMessage({ id: 'pages.contact.labelTargetLanguage' })}
              >
                <FormattedMessage id="pages.contact.labelTargetLanguage" />
              </label>
              <input
                type="text"
                name={intl.formatMessage({ id: 'pages.contact.labelTargetLanguage' })}
                id="target"
              />
            </div>
            <div className="field">
              <label
                htmlFor={intl.formatMessage({ id: 'pages.contact.labelMessage' })}
                id={intl.formatMessage({ id: 'pages.contact.labelMessage' })}
              >
                {' '}
                <FormattedMessage id="pages.contact.labelMessage" />
              </label>
              <textarea
                name={intl.formatMessage({ id: 'pages.contact.labelMessage' })}
                id="message"
                rows="4"
                required
              ></textarea>
            </div>
            <ul className="actions">
              <li>
                <input
                  type="submit"
                  value={intl.formatMessage({ id: 'pages.contact.buttonSend' })}
                  className="special"
                />
              </li>
              <li>
                <input
                  type="reset"
                  value={intl.formatMessage({ id: 'pages.contact.buttonReset' })}
                />
              </li>
            </ul>
          </form>)}
      <ul className="icons">
        <li>
          <a
            href="https://facebook.com/chinotrad"
            className="icon fa-facebook"
            target="blank"
          >
            <span className="label">Facebook</span>
          </a>
        </li>

        <li>
          <a
            href="https://www.linkedin.com/company/chinotrad"
            className="icon fa-linkedin"
            target="blank"
          >
            <span className="label">Linkedin</span>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Contact
