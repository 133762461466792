import React from 'react'
import PropTypes from 'prop-types'
import logo from '../images/logo.png'
import {FormattedMessage} from "gatsby-plugin-intl"

const Header = (props) => {

  return (
    <header id="header" style={props.timeout ? { display: 'none' } : {}}>
      <div className="logo">
        <span className="icon">
          <img src={logo} alt="Bruna Martinelli" />
        </span>
      </div>
      <div className="content">
        <div className="inner">
          <h1>
            <FormattedMessage id="home.title" />
          </h1>
          <h2>
            <FormattedMessage id="home.caption" />
          </h2>
          <p>
            <FormattedMessage id="home.subtitle" />
          </p>
        </div>
      </div>
      <nav>
        <ul>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('intro')
              } }
            >
              <FormattedMessage id="pages.intro.title" />
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('work')
              } }
            >
              <FormattedMessage id="pages.work.title" />
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('about')
              } }
            >
              <FormattedMessage id="pages.about.title" />
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('contact')
              } }
            >
              <FormattedMessage id="pages.contact.title" />
            </button>
          </li>
        </ul>
      </nav>
    </header>
  )
}

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header