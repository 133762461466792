import React from 'react'
import { Link } from "gatsby"
import PropTypes from 'prop-types'

const Locales = props => (
  <div id="locales">
    <nav>
      <ul>
        <li>
          <Link to="/pt/">PT</Link>
        </li>
        <li>
          <Link to="/en/">EN</Link>
        </li>
      </ul>
    </nav>
  </div>
)

Locales.propTypes = {
  timeout: PropTypes.bool,
}

export default Locales
